import { render, staticRenderFns } from "./box2.vue?vue&type=template&id=783c9ee1&scoped=true&"
import script from "./box2.vue?vue&type=script&lang=js&"
export * from "./box2.vue?vue&type=script&lang=js&"
import style0 from "./box2.vue?vue&type=style&index=0&id=783c9ee1&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "783c9ee1",
  null
  
)

export default component.exports