<template>
  <div class="box">
    <div class="box2">
      <div class="menu-tool">
        <div class="menu-tool-tabs">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="全部(10)" name="first"></el-tab-pane>
            <el-tab-pane label="压力异常(1)" name="second"></el-tab-pane>
            <el-tab-pane label="离线(0)" name="third"></el-tab-pane>
            <el-tab-pane label="关注(0)" name="fourth"></el-tab-pane>
          </el-tabs>
        </div>
        <div class="menu-tool-right">
          <div class="menu-tool-right-toogle">
            <el-button size="mini" type="primary" plain>卡片模式</el-button>
            <el-button size="mini" plain>列表模式</el-button>
          </div>
          <div class="menu-tool-right-input">
            <el-input v-model="search" size="mini" placeholder="请输入监测点名称"></el-input>
          </div>
        </div>
      </div>
      <div class="wrapper-card">
        <div class="card-item" v-for="item in list" :key="item" @click="toDetail(item)">
          <div class="card-item-title">
            <div class="card-item-title-left">
              <i class="iconfont icon-guanwang icon"></i>
              <div class="card-item-title-text">
                <div class="title">东环路1号管网</div>
                <div class="subtitle">定边县</div>
              </div>
            </div>
            <div class="card-item-title-collect"><i class="iconfont icon-guanwang icon"></i></div>
          </div>
          <div class="card-item-content">
            <div class="warning-line"></div>
            <div :id="'card-item-chart'+item" style="flex: 1;width: 30%;height: 150px;"></div>
            <div class="card-item-chart-info">
              <div class="info-item-data">
                <span>流量： </span>
                <span class="data">65m³/h</span>
              </div>
              <div class="info-item-line"></div>
              <div class="info-item-time">2024-03-20 18:00:00</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
import dayjs from "dayjs"
export default {
  data() {
    return {
      list: [1,2,3,4,5,6,7,8,9,10],
      activeName: 'first'
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    toDetail(item) {
      this.$router.push({ name: 'overviewPipelineNetworkDetail', params: {row: item}})
    },
    setChart(v) {
      var myChart = echarts.init(document.getElementById('card-item-chart'+v));
      var option = {
        title: {
          subtext:'Mpa'
        },
        backgroundColor: '',
        color: ['#60EEFF'],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter:function(params) {
            return params[0].name +'<br>'+params[0].seriesName+': '+params[0].data+'(m³/h)'
          },
        },
        grid: {
          left: '0%',
          right: '0%',
          bottom: '0%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          axisTick: {
            show: false
          },
          data: ['流量'],
        },
        yAxis: {
          type: 'value',
          boundaryGap: [0, 0.01],
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          }
        },
        series: [
          {
            name: '流量',
            type: 'bar',
            data: [0.5,0.8,0.3,1],
            showBackground: true,
            backgroundStyle: {
              color: 'rgba(180, 180, 180, 0.2)'
            },
            barWidth: 20,
            // label: {
            //   show: true,
            //   position: 'right',
            //   valueAnimation: true
            // },
            itemStyle: {
              color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                { offset: 0, color: '#79A1E2' },
                { offset: 1, color: '#48B9DA' }
              ]),
              borderRadius: [4,4,0,0],
            },
          }
        ],
        legend: {
          show: false
        }
      };
      myChart.setOption(option);
    },
  },
  mounted() {
    this.list.forEach(v=>{
      this.setChart(v)
    })
  },
};
</script>

<style lang="less" scoped>
.main {
  width: 100%;
  height: 480px;
}
.box-title {
  color: #202020;
  font-size: 18px;
  padding: 0 16px;
  height: 50px;
  line-height: 50px;
  font-weight: bold;
}
.box {
  background: #FAFAFA;
  .box2 {
    border-radius: 10px;
    background-color: #FFF;
  }
}
.menu-tool {
  border-radius: 10px;
  background-color: #FFF;
  border-bottom: 14px solid #FAFAFA;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .menu-tool-tabs {
    width: 75%;
  }
  .menu-tool-right {
    display: flex;
    justify-content: center;
    align-items: center;
    .menu-tool-right-toogle {
      margin-right: 30px;
    }
    .menu-tool-right-input {

    }
  }
}
.wrapper-card {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  font-size: 14px;
  background: #f0f0f0;
  border-radius: 10px;
  .card-item {
    width: 19.3%;
    height: 220px;
    background: #FFF;
    border-radius: 10px;
    margin: 5px;
    padding: 10px;
    box-sizing: border-box;
    transition: all .3s ease;
    cursor: pointer;
    &:hover {
      background: #ffffff65;
    }
    .card-item-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .card-item-title-left {
        display: flex;
        align-items: center;
        .icon {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          text-align: center;
          line-height: 30px;
          background-color: #D9F8E2;
          color: #32D965;
          margin-right: 10px;
        }
        .card-item-title-text {
          .title {
            font-size: 15px;
            font-weight: bold;
          }
          .subtitle {
            font-size: 12px;
            color: #999;
          }
        }
      }
      .card-item-title-collect {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        text-align: center;
        line-height: 25px;
        background-color: #EBEBEB;
        color: #A0A0A0;
      }
    }
    .card-item-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      .warning-line {
        width: 60px;
        height: 2px;
        border-top: 2px dashed red;
        position: absolute;
        bottom: 80px;
        left: 25px;
        z-index: 1;
      }
      .card-item-chart-info {
        flex: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 30px;
        .info-item-data {
          .data {
            color: #00A0EA;
            font-weight: bold;
          }
        }
        .info-item-line {
          width: 100%;
          height: 1px;
          background-color: #A0A0A0;
          margin: 30px 0;
        }
      }
    }
  }
}
</style>